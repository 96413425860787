<template>
    <SimpleTable formclass="Notfis/form" endpoint="notfis" :aditionalColumns="headers">
        <template>
            <h1>Notfis</h1>
            <v-divider class="mb-4" />
        </template>
        <template v-slot:extra-column="item">
            <v-layout wrap my-1>
                <v-flex xs12>
                    <v-tooltip v-model="show" top color="secondary">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" class="ma-0" color="error" v-if="item.notFound" label>
                                <v-icon left> error </v-icon>Bipado e não localizado na base</v-chip>
                            <v-chip v-bind="attrs" v-on="on" class="ma-0" color="success" v-else-if="item.validated">Bipado</v-chip>
                            <v-chip v-bind="attrs" v-on="on" class="ma-0" outlined color="error" v-else>Aguardando</v-chip>
                        </template>
                        <span v-if="item.bippedCounter">Bipado {{ item.bippedCounter }} vez(es)</span>
                        <span v-else>Ainda não bipado</span>
                    </v-tooltip>
                </v-flex>
                <v-flex xs12>
                    <v-tooltip v-model="show" top color="secondary" v-if="item.cte">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" x-small color="secondary" dark class="mr-1">CTE</v-btn>
                        </template>
                        <span>{{ item.cte }}</span>
                    </v-tooltip>
                    <v-tooltip v-model="show" top color="secondary" v-if="item.address">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" x-small color="secondary" dark class="mr-1">Endereço</v-btn>
                        </template>
                        <span>{{ item.address }}</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t(""), value: "extra-column", align: "right", sortable: true },
                // { text: this.$t("CTE"), value: "cte", align: "right", sortable: true },
                { text: this.$t("NFe"), value: "invoice", align: "right", sortable: true },
                // { text: this.$t("Endereço"), value: "address", align: "right", sortable: true },
                { text: this.$t("CEP"), value: "zipCode", align: "right", sortable: true },
                { text: this.$t("CPF ou CNPJ"), value: "document", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
                { text: this.$t("Pacotes"), value: "numberOfPackages", align: "right", sortable: true },
                { text: this.$t("Embarque"), value: "shipment", align: "right", sortable: true },
                { text: this.$t("Inserido na Base"), value: "createdAt", align: "right", sortable: true },
            ],
        };
    },
};
</script>